<template>
  <div class="mealSet">
    <div class="mealSet-right">
      <div class="mealSet-right-cont">
        <div class="mealSet-right-cont-head">
          <div class="mealSet-right-cont-head-list" v-if="0">
            <span style="width:130px;">选择门店：</span>
            <el-select v-model="value" placeholder="请选择" class="select">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="mealSet-right-cont-head-list" v-if="0">
            <span>选择分类：</span>
            <el-select v-model="value" placeholder="请选择" class="select">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="mealSet-right-cont-head-list">
            <!-- <span>搜索：</span> -->
            <el-input
              placeholder="请输入搜索关键字"
              v-model="input"
              @input="inputs"
              clearable
              @clear="clears"
              class="input radius"
            >
            </el-input>
          </div>
          <div class="mealSet-right-cont-head-list">
            <span>状态：</span>
            <el-select
              v-model="options3_value"
              placeholder="请选择次卡状态"
              class="select"
            >
              <el-option
                v-for="item in options3"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div class="mealSet-right-cont-head-list" style="margin-left:10px;">
            <el-button
              type="primary"
              style="width:100px"
              @click="searchs"
              icon="el-icon-search"
              >查询</el-button
            >
          </div>
          <div class="mealSet-right-cont-head-list">
            <el-button
              type="danger"
              style="width:120px"
              plain
              icon="el-icon-plus"
              @click="edit(1)"
              >添加次卡</el-button
            >
          </div>

          <!-- <div class="mealSet-right-cont-head-list">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchs"
              style="width:50%"
              >查询</el-button
            >
            
            <el-button
              v-if="status"
              type="primary"
              plain
              @click="getList(1,1)"
              style="width:50%"
              >查看已下架次卡</el-button
            >
            <el-button
              v-if="!status"
              type="primary"
              plain
              @click="getList(0,1)"
              style="width:50%"
              >查看上架次卡</el-button
            >
          </div> -->
          <!-- <div class="mealSet-right-cont-head-list">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              @click="edit(1)"
              style="width:50%"
              >添加</el-button
            >
          </div> -->
        </div>
        <el-table
          :data="userSearchData"
          :border="$table('border')"
          :stripe="$table('stripe')"
          :header-cell-style="{ background: $table('bgcolor') }"
          style="width: 100%"
        >
          <!-- <el-table-column prop="id" label="序号" :width="$table('width')"> -->
          <el-table-column prop="id" label="序号" min-width="60">
            <template slot-scope="scope">
              <span v-if="pages < 2">
                {{ scope.$index + 1 }}
              </span>
              <span v-else>
                <!-- {{ scope.$index + 1 + pages * userSearchData.length }} -->
                {{ (scope.$index + 1) + pages * 15 -15}}
              </span>
              
            </template>
          </el-table-column>
          <el-table-column prop="picture" label="主图" min-width="90">
            <template slot-scope="scope">
              <div class="mealSet-photo">
                <img
                  :src="scope.row.picture"
                  :alt="scope.row.name"
                  v-if="scope.row.picture"
                />
                <img
                  src="../../assets/none.png"
                  :alt="scope.row.name"
                  v-if="!scope.row.picture"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" min-width="140"></el-table-column>
          <el-table-column prop="price" label="价格(￥)" min-width="120"></el-table-column>
          <el-table-column prop="exp_date" label="有效期" min-width="120">
            <template slot-scope="scope">
              <span v-if="!scope.row.exp_type">永久</span>
              <span v-if="scope.row.exp_type == '1'"
                >{{ scope.row.exp_date }}天</span
              >
              <span v-if="scope.row.exp_type == '2'"
                >{{ scope.row.exp_date }}月</span
              >
              <span v-if="scope.row.exp_type == '3'"
                >{{ scope.row.exp_date }}年</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="projects" label="套餐内容" min-width="200">
            <template slot-scope="scope">
              <div>{{scope.row.project.name}}</div>
              <!-- <div> -->
                <!-- 项目：<span>{{ getCommisionsNum(scope.row.projects, 1) }}</span> -->

                <!-- 商品：<span>{{ getCommisionsNum(scope.row.projects, 2) }}</span> -->
              <!-- </div> -->
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">上架中</span>
              <span style="color: #bbbbbb;" v-if="scope.row.status == 0"
                >已下架</span
              >
            </template>
          </el-table-column>
          <el-table-column label="编辑" fixed="right" min-width="150">
            <template slot-scope="scope">
              <div class="btn-box">
                <el-button
                  type="primary"
                  :data-d="scope.row"
                  plain
                  size="small"
                  @click="edit(0, scope)"
                  >编辑</el-button
                >
                <el-button type="danger" plain size="small" @click="del(scope)"
                  >删除</el-button
                >

                <!-- <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="delivery(scope)"
                  >分发</el-button
                > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="mealSet-right-cont-page">
          <el-pagination
            v-if="$pageStatus(total)[0]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="$pageStatus(total)[1]"
            layout="total, prev, pager, next, jumper"
            :hide-on-single-page="total == true"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="type ? '新增资料' : '修改资料'"
      :visible.sync="dialogVisible"
      width="1200px"
      :close-on-click-modal="false"
    >
      <div class="mealSet-edit" v-if="0">
        <div class="mealSet-edit-list">
          <div class="mealSet-edit-list-item">
            <span>次卡名称</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.name"
              clearable
            >
            </el-input>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:115px;">价格：</span>
            <el-input-number
              style="width: 243px;margin-left: -16px;"
              v-model="formLabelAlign.price"
              :precision="2"
              :step="0.1"
              :min="0.01"
              :max="999999"
            ></el-input-number>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:100px;">排序：</span>
            <el-input-number
              style="width: 243px;margin-left: -16px;"
              v-model="formLabelAlign.sort"
              :min="1"
              :max="999999"
            ></el-input-number>
          </div>
        </div>

        <div class="mealSet-edit-list">
          <div class="mealSet-edit-list-item">
            <span style="width:110px;">有 效 期：</span>
            <el-input-number
              style="width: 243px;margin-left: -16px;"
              v-model="formLabelAlign.exp_date"
              :min="1"
              :max="999999"
            ></el-input-number>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:100px;">有效期类型：</span>
            <el-select
              v-model="formLabelAlign.exp_type"
              placeholder="请选择"
              style="width: 243px;"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:100px;">过期提醒：</span>
            <el-input-number
              style="width: 243px;margin-left: -16px;"
              v-model="formLabelAlign.exp_alarm_day"
              :min="1"
              :max="999999"
            ></el-input-number>
          </div>
        </div>
        <div class="mealSet-edit-list" v-if="0">
          <div class="mealSet-edit-list-item">
            <span style="width:110px;">提成金额：</span>
            <el-input-number
              style="width: 243px;margin-left: -16px;"
              v-model="formLabelAlign.commi_amount"
              :precision="2"
              :min="0"
              :step="0.1"
              :max="999999"
            ></el-input-number>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:115px;">提成类型：</span>
            <el-switch
              v-model="formLabelAlign.commi_type"
              active-text="百分比提成"
              inactive-text="固定提成"
            >
            </el-switch>
          </div>
          <div class="mealSet-edit-list-item"></div>
        </div>

        <div class="mealSet-edit-list" style="display:block;">
          <div class="mealSet-edit-list-item">
            <span style="width:100px;">项目设置：</span>
          </div>
          <div
            v-for="(item, index) in formLabelAlign.projects"
            :key="index"
            style="display:flex;align-items: center;justify-content: center;"
          >
            <div class="mealSet-edit-list-item" v-if="item.status">
              <el-popconfirm
                title="确定删除吗？"
                @confirm="delcommisions(index, 0)"
              >
                <button
                  slot="reference"
                  class="mealSet-edit-list-item-btn"
                ></button>
              </el-popconfirm>

              <div class="mealSet-edit-list-item" v-if="item.status">
                <span style="width:115px;">项目：</span>
                <el-select
                  filterable
                  v-model="formLabelAlign.projects[index].project_id"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="items in options1"
                    :key="items.id"
                    :label="items.name"
                    :value="items.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="mealSet-edit-list-item" v-if="item.status">
                <span style="width:115px;">次数：</span>
                <el-input-number
                  style="width: 243px;margin-left: -16px;"
                  v-model="formLabelAlign.projects[index].count"
                  :min="1"
                  :max="999999"
                ></el-input-number>
              </div>
            </div>
          </div>
          <div class="mealSet-edit-list-item">
            <el-button
              type="primary"
              @click="addcommisions(0)"
              icon="el-icon-circle-plus-outline"
              >添加服务项目</el-button
            >
          </div>
        </div>

        <div class="mealSet-edit-list" style="display:block;" v-if="0">
          <div class="mealSet-edit-list-item">
            <span style="width:100px;">商品设置：</span>
          </div>
          <div
            v-for="(item, index) in formLabelAlign.products"
            :key="index"
            style="display:flex;align-items: center;justify-content: center;"
          >
            <div class="mealSet-edit-list-item" v-if="item.status">
              <el-popconfirm
                title="确定删除吗？"
                @confirm="delcommisions(index, 1)"
              >
                <button
                  slot="reference"
                  class="mealSet-edit-list-item-btn"
                ></button>
              </el-popconfirm>

              <div class="mealSet-edit-list-item" v-if="item.status">
                <span style="width:115px;">商品：</span>
                <el-select
                  v-model="formLabelAlign.products[index].product_id"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="items in options"
                    :key="items.id"
                    :label="items.name"
                    :value="items.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="mealSet-edit-list-item" v-if="item.status">
                <span style="width:115px;">次数：</span>
                <el-input-number
                  style="width: 243px;margin-left: -16px;"
                  v-model="formLabelAlign.products[index].count"
                  :min="1"
                  :max="999999"
                ></el-input-number>
              </div>
            </div>
          </div>
          <div class="mealSet-edit-list-item">
            <el-button
              type="primary"
              @click="addcommisions(1)"
              icon="el-icon-circle-plus-outline"
              >添加服务商品</el-button
            >
          </div>
        </div>

        <div class="mealSet-edit-list" v-if="0">
          <div class="mealSet-edit-list-item">
            <span style="width:100px;">小程序配置：</span>
            <el-checkbox v-model="formLabelAlign.wxapp"
              >启用小程序
            </el-checkbox>
            <!-- <el-checkbox
              v-model="formLabelAlign.wxstatus"
              :disabled="!formLabelAlign.wxapp"
              >启用小程序预约</el-checkbox
            > -->
          </div>
        </div>
        <div class="mealSet-edit-list">
          <div class="mealSet-edit-list-item">
            <span style="width:100px;">是否上架：</span>
            <el-radio v-model="formLabelAlign.status" label="1">上架</el-radio>
            <el-radio v-model="formLabelAlign.status" label="0">下架</el-radio>
          </div>
        </div>
        <div class="mealSet-edit-list" v-if="0">
          <div class="mealSet-edit-list-item">
            <span style="width:100px;">服务内容：</span>
            <el-select
              v-model="formLabelAlign.work_peopel"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="mealSet-edit-list">
          <span style="margin-right:15px;">商品主图：</span>

          <el-image
            v-if="formLabelAlign.picture"
            style="width: 148px; height: 148px"
            :src="formLabelAlign.picture"
            :preview-src-list="[formLabelAlign.picture]"
          >
          </el-image>

          <el-upload
            action="/api/app/meiye/upload/shop_photo"
            class="avatar-uploader"
            :show-file-list="false"
            :http-request="update"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="mealSet-edit-list" style="display:block;">
          <div class="mealSet-edit-list-item" style="width:33.33%;height:60px;">
            商品描述：
          </div>
          <!-- <vue-ueditor-wrap v-model="formLabelAlign.detail" :config="config" /> -->
          <i-quill-editor :content="formLabelAlign.detail" @textChange="handleTextChange"></i-quill-editor>
        </div>
      </div>

      <div class="mealSet-edit">
        <div class="mealSet-edit-list">
          <div class="mealSet-edit-list-img">
            <el-image
              v-if="formLabelAlign.picture"
              style="width: 160px; height: 160px;"
              :src="formLabelAlign.picture"
              :preview-src-list="[formLabelAlign.picture]"
            >
            </el-image>
            <el-image
              v-if="!formLabelAlign.picture"
              style="width: 160px; height: 160px;"
              :src="require('../../assets/none.png')"
              :preview-src-list="[require('../../assets/none.png')]"
            >
            </el-image>

            <el-upload
              action="/api/app/meiye/upload/shop_photo"
              class="avatar-uploader"
              :show-file-list="false"
              :http-request="update"
            >
              <el-button
                size="small"
                type="primary"
                style="margin:15px 0;"
                v-if="type"
                >上传主图</el-button
              >
              <el-button
                size="small"
                type="primary"
                style="margin:15px 0;"
                v-if="!type"
                >更换主图</el-button
              >
              <div slot="tip" class="el-upload__tip">
                <p>建议上传正方形尺寸大小</p>
                <p>小于500kb，200x200像素最佳</p>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="mealSet-edit-list" style="min-width: 442px;">
          <div class="mealSet-edit-list-item">
            <span style="width:130px;">次卡名称：</span>
            <div class="mealSet-edit-list-item-input">
              <el-input
                placeholder="请输入内容"
                v-model="formLabelAlign.name"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:130px;">次卡价格：</span>
            <div class="mealSet-edit-list-item-input">
              <el-input-number
                style="width: 100%;"
                v-model="formLabelAlign.price"
                :precision="2"
                :step="0.1"
                :min="0.01"
                :max="999999"
              ></el-input-number>
            </div>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:130px;">提成金额：</span>
            <div class="mealSet-edit-list-item-input">
              <div class="nums">
                <div class="nums-input">
                  <el-input-number
                    v-model="formLabelAlign.commi_amount"
                    controls-position="right"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :max="9999999"
                  ></el-input-number>
                </div>
                <div class="nums-select">
                  <el-select
                    v-model="formLabelAlign.commi_type"
                    placeholder="请选择提成类型"
                  >
                    <el-option
                      v-for="item in zd_amount"
                      :key="item.label"
                      :label="item.value"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:130px;">次卡排序：</span>
            <div class="mealSet-edit-list-item-input">
              <el-input-number
                style="width: 100%;"
                v-model="formLabelAlign.sort"
                :min="1"
                :max="999999"
              ></el-input-number>
            </div>
          </div>
        </div>
        <div
          class="mealSet-edit-list"
          style="min-width: 390px;margin-right: 30px;"
        >
          <div class="mealSet-edit-list-item" v-if="0">
            <span style="width:130px;" v-show="!formLabelAlign.commi_type"
              >提成金额(元)：</span
            >
            <span style="width:130px;" v-show="formLabelAlign.commi_type"
              >提成金额(%)：</span
            >
            <div class="mealSet-edit-list-item-input">
              <el-input-number
                v-model="formLabelAlign.commi_amount"
                :precision="2"
                :min="0"
                :step="0.1"
                :max="999999"
                style="width: 100%;"
              ></el-input-number>
              <el-switch
                style="width: 100%;"
                v-model="formLabelAlign.commi_type"
                active-text="百分比"
                inactive-text="固定"
              >
              </el-switch>
            </div>
          </div>
          <!-- <div class="mealSet-edit-list-item">
            <span style="width:130px;">次卡排序：</span>
            <div class="mealSet-edit-list-item-input">
              <el-input-number
                style="width: 100%;"
                v-model="formLabelAlign.sort"
                :min="1"
                :max="999999"
              ></el-input-number>
            </div>
          </div> -->
          <div class="mealSet-edit-list-item">
            <span style="width:130px;">次卡期限：</span>
            <div class="mealSet-edit-list-item-input">
              <el-input-number
                v-show="formLabelAlign.exp_type"
                style="width: 100%;"
                v-model="formLabelAlign.exp_date"
                :min="1"
                :max="999999"
              ></el-input-number>
              <el-select
                v-model="formLabelAlign.exp_type"
                placeholder="请选择过期类型"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:130px;">过期提醒：</span>
            <div class="mealSet-edit-list-item-input">
              <el-input-number
                style="width: 100%;"
                v-model="formLabelAlign.exp_alarm_day"
                :min="1"
                :max="999999"
              ></el-input-number>
            </div>
          </div>
          <div class="mealSet-edit-list-item">
            <span style="width:130px;">是否上架：</span>
            <div class="mealSet-edit-list-item-input">
              <el-radio v-model="formLabelAlign.status" label="1"
                >上架</el-radio
              >
              <el-radio v-model="formLabelAlign.status" label="0"
                >下架</el-radio
              >
            </div>
          </div>
        </div>
      </div>

      <div class="mealSet-edit-other">
        <div class="mealSet-edit-other-title">项目设置</div>
        <div class="mealSet-edit-other-cont">
          <div
            v-for="(item, index) in formLabelAlign.projects"
            :key="index"
            style="display:flex;align-items: center;justify-content: center;"
          >
            <div class="mealSet-edit-list-item" v-if="item.status">
              <span style="width:115px;">项目{{ index + 1 }}：</span>
              <el-select
                v-model="formLabelAlign.projects[index].project_id"
                placeholder="请选择项目"
                filterable
              >
                <el-option
                  v-for="items in options1"
                  :key="items.id"
                  :label="items.name"
                  :value="items.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="mealSet-edit-list-item" v-if="item.status">
              <span style="width:115px;">次数：</span>
              <el-input-number
                style="width: 243px;margin-left: -16px;"
                v-model="formLabelAlign.projects[index].count"
                :min="1"
                :max="999999"
              ></el-input-number>
            </div>
            <div class="mealSet-edit-list-item" v-if="item.status">
              <el-popconfirm
                title="确定删除吗？"
                @confirm="delcommisions(index, 0)"
              >
                <div class="mealSet-edit-list-item-btn" slot="reference">
                  <i class="el-icon-close"></i>
                </div>
              </el-popconfirm>
            </div>
          </div>
          <div class="mealSet-edit-list-item mealSet-edit-list-item-display">
            <el-button
              type="primary"
              @click="addcommisions(0)"
              icon="el-icon-circle-plus-outline"
              >添加服务项目</el-button
            >
          </div>
        </div>
      </div>
      <div class="mealSet-edit-other" v-if="0">
        <div class="mealSet-edit-other-title">商品设置</div>
        <div class="mealSet-edit-other-cont">
          <div
            v-for="(item, index) in formLabelAlign.products"
            :key="index"
            style="display:flex;align-items: center;justify-content: center;"
          >
            <div class="mealSet-edit-list-item" v-if="item.status">
              <span style="width:115px;">商品：</span>
              <el-select
                v-model="formLabelAlign.products[index].product_id"
                placeholder="请选择"
                @change="change_products"
              >
                <el-option
                  v-for="items in options"
                  :key="items.id"
                  :label="items.name"
                  :value="items.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="mealSet-edit-list-item" v-if="item.status">
              <span style="width:115px;">次数：</span>
              <el-input-number
                style="width: 243px;margin-left: -16px;"
                v-model="formLabelAlign.products[index].count"
                :min="1"
                :max="999999"
              ></el-input-number>
            </div>
            <div class="mealSet-edit-list-item" v-if="item.status">
              <el-popconfirm
                title="确定删除吗？"
                @confirm="delcommisions(index, 1)"
              >
                <div class="mealSet-edit-list-item-btn" slot="reference">
                  <i class="el-icon-close"></i>
                </div>
              </el-popconfirm>
            </div>
          </div>
          <div class="mealSet-edit-list-item mealSet-edit-list-item-display">
            <el-button
              type="primary"
              @click="addcommisions(1)"
              icon="el-icon-circle-plus-outline"
              >添加服务商品</el-button
            >
          </div>
        </div>
      </div>
      <div class="mealSet-edit-other">
        <div class="mealSet-edit-other-title">
          商品描述：
        </div>
        <div class="projectSet-edit-other-cont">
          <!-- <vue-ueditor-wrap v-model="formLabelAlign.detail" :config="config" /> -->
          <i-quill-editor :content="formLabelAlign.detail" @textChange="handleTextChange"></i-quill-editor>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cencels">取 消</el-button>
        <el-button v-if="type" type="primary" @click="saves(1)">添加</el-button>
        <el-button v-if="!type" type="primary" @click="saves(0)"
          >保存修改</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="分发" :visible.sync="dialogVisible1" width="90%">
      <div class="mealSet-edit">
        <div class="mealSet-edit-list">
          <div class="mealSet-edit-list-item">
            <span>商品名称：</span>
            <el-input
              placeholder="请输入内容"
              v-model="fromData.name"
              clearable
              disabled
            >
            </el-input>
          </div>
        </div>
        <div class="mealSet-edit-list">
          <div class="mealSet-edit-list-item">
            <span>分发到所有门店：</span>
            <el-switch
              v-model="fromData.all"
              active-text="全部"
              inactive-text="指定"
            >
            </el-switch>
          </div>
        </div>
        <div class="mealSet-edit-list" v-if="!fromData.all">
          <div class="mealSet-edit-list-item">
            <span>请选择分发门店：</span>
            <el-select v-model="fromData.list" multiple placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delivery(0)">取 消</el-button>

        <el-button type="primary" @click="distribute">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import VueUeditorWrap from "vue-ueditor-wrap";
import IQuillEditor from '../../components/home/i-quill-editor.vue';

export default {
  // components: { VueUeditorWrap },
  components: { IQuillEditor },
  data() {
    return {
      msg: "",
      config: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: "http://35.201.165.105:8000/controller.php",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的商品，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "./UEditor/",
        zIndex: 3500,
      },
      dialogVisible: false,
      type: "", //保存和修改标识 1添加0编辑更新
      dialogVisible1: false,
      userData: [],
      userSearchData: [],
      userDataIndex: "",
      formLabelAlign: {
        detail: ''
      },
      options: [],
      options1: [],
      options2: [
        {
          value: 0,
          label: "永久",
        },
        {
          value: 1,
          label: "天",
        },
        {
          value: 2,
          label: "月",
        },
        {
          value: 3,
          label: "年",
        },
      ],
      zd_amount: [
        { label: 1, value: "元" },
        { label: 2, value: "%" },
      ], //指定类型
      currentPage4: 1,
      total: 1,
      pages: "",
      value: "0",
      input: "",
      radio: "",
      fromData: {
        id: "",
        name: "",
        all: false,
        list: [],
      },
      status: 1, //状态
      options3: [
        { id: 1, name: "上架" },
        { id: 0, name: "下架" },
        { id: 2, name: "全部" },
      ], //状态
      options3_value: 1,
    };
  },
  methods: {
    /**
     * 富文本内容改变
     */
    handleTextChange(value) {
      // console.log('textChange', value);
      this.formLabelAlign.detail = value ? value : '<p></p>';
    },
    /* 恢复搜索，搜索清除恢复内容 */
    inputs(val) {
      if (!val) {
        this.getList();
      }
    },
    /* 获取商品项目数量 */
    getCommisionsNum(obj, type) {
      let len = obj.length;
      if (!len) {
        return 0;
      }
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (type == 1) {
          if (obj[i].status && obj[i].project_id) {
            num++;
          }
        } else {
          if (obj[i].status && obj[i].product_id) {
            num++;
          }
        }
      }
      return num;
    },
    tabType(index) {
      this.tabIndex = index;
    },
    getType() {
      this.$axios({
        href: "/api/app/meiye/project/store",
        data: { status: 1 },
      }).then((res) => {
        console.log(res.data);
        this.options1 = res.data;
      });

      this.$axios({
        href: "/api/app/meiye/product/store",
        data: { page: -1 },
      }).then((res) => {
        console.log(res.data);
        this.options = res.data.items;
      });
    },
    searchs() {
      let val = this.input;
      this.pages = 1;
      if (this.options3_value || !this.options3_value) {
        this.getList();
        return false;
      }
      if (!val) {
        this.$message({
          message: "关键字不能为空",
          type: "warning",
        });
        return false;
      }
      this.getList();
    },
    changes() {
      if (!this.input) {
        this.clears();
        return false;
      }
      this.searchs();
    },
    clears() {
      this.getList();
    },
    getList() {
      // let Status = !obj ? 1 : 0;
      let page = this.pages;

      let status =
        this.options3_value == 2
          ? { page: page, name: this.input }
          : { status: this.options3_value, page: page, name: this.input }; //2等于全部状态

      this.status = this.options3_value;
      //  console.log(this.page,status)
      // if (type) {
      //   this.pages = 1;
      // }
      this.$axios({
        href: "/api/app/meiye/project_card/store",
        data: status,
      }).then((res) => {
        console.log(res.data.data);

        let len = res.data.data.items.length;

        this.total = res.data.data.total;

        // if (!len) {
        //   return false;
        // }

        for (let i = 0; i < len; i++) {
          //    res.data.data.items[i].commi_type =
          //     res.data.data.items[i].commi_type - 0 > 1 ? true : false;
          res.data.data.items[i].status = res.data.data.items[i].status
            ? "1"
            : "0";
          res.data.data.items[i].projects = [
            {
              project_id: res.data.data.items[i].project.id,
              count: res.data.data.items[i].project_count,
              status: true,
            },
          ];

          //   for (let l = 0; l <  res.data.items[i].projects.length; l++) {
          //      res.data.items[i].projects[l].status = true;
          //     res.data.items[i].projects[l].m = "m02";
          //      res.data.items[i].projects[l].project_ids = 1;
          //     // console.log(res.data[i].projects[l].project_id)
          //   }
          //   for (let k = 0; k < res.data[i].products.length; k++) {
          //     res.data[i].products[k].status = true;
          //     res.data[i].products[k].m = "m02";
          //     res.data[i].products[k].product_ids = 1;
          //     // console.log(res.data[i].products[k].product_id)
          //   }
        }
        // this.getList(1)

        this.userSearchData = res.data.data.items;
        this.userData = res.data.data.items;
        console.log(res.data.data.items);
      });
    },
    updateList(obj) {
      this.$axios({
        href: "/api/app/meiye/project_card/edit",
        data: obj,
      }).then(() => {
        this.getList();
        this.cencels();
        this.$message({
          type: "success",
          message: "编辑成功!",
        });
      });
    },
    addList(obj) {
      this.$axios({
        href: "/api/app/meiye/project_card/add",
        data: obj,
      }).then(() => {
        this.getList();
        this.cencels();
        this.$message({
          type: "success",
          message: "添加成功!",
        });
      });
    },
    delList(obj) {
      this.$axios({
        href: "/api/app/meiye/project_card/del",
        data: obj,
      }).then(() => {
        this.getList();
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    /* 删除提成设置 */
    delcommisions(index, type) {
      console.log(index, this.userDataIndex, type);
      if (this.userDataIndex !== "") {
        if (!type) {
          this.userSearchData[this.userDataIndex].projects.splice(index, 1);
        } else {
          this.userSearchData[this.userDataIndex].products.splice(index, 1);
        }
      }

      if (!type) {
        if (this.type) {
          this.formLabelAlign.projects.splice(index, 1);
          return false;
        }
        this.formLabelAlign.projects[index].m = "m03";
        this.formLabelAlign.projects[index].status = false;
      } else {
        if (this.type) {
          this.formLabelAlign.products.splice(index, 1);
          return false;
        }
        this.formLabelAlign.products[index].m = "m03";
        this.formLabelAlign.products[index].status = false;
      }
    },
    /* 添加提成设置 */
    addcommisions(type) {
      if (this.userDataIndex !== "") {
        console.log(type);
        if (!type) {
          if (
            this.userSearchData[this.userDataIndex].length >= 1 ||
            this.formLabelAlign.projects.length >= 1
          ) {
            this.$message({
              message: "一个次卡只能对应一个服务",
              type: "warning",
            });
            return false;
          }
          this.userSearchData[this.userDataIndex].projects.push({
            id: 0,
            project_id: "",
            count: 1,
            m: "m01",
            status: true,
          });
          return false;
        }

        // this.userSearchData[this.userDataIndex].products.push({
        //   id: 0,
        //   product_id: "",
        //   count: 1,
        //   m: "m01",
        //   status: true,
        // });
        // return false;
      }

      if (!type) {
        console.log(
          this.formLabelAlign.projects,
          this.userSearchData[this.userDataIndex]
        );
        if (this.formLabelAlign.projects.length >= 1) {
          this.$message({
            message: "一个次卡只能对应一个服务",
            type: "warning",
          });
          return false;
        }
        this.formLabelAlign.projects.push({
          id: 0,
          project_id: "",
          count: 1,
          m: "m01",
          status: true,
        });
        return false;
      }

      //   this.formLabelAlign.products.push({
      //     id: 0,
      //     product_id: "",
      //     count: 1,
      //     m: "m01",
      //     status: true,
      //   });
    },
    /* 添加更新 */
    edit(index, obj) {
      if (index) {
        this.userDataIndex = "";
      }
      // console.log(obj);
      this.type = index;
      if (!index) {
        // this.userData.wxapp = this.userData.wxapp - 0 ? true : false;
        // this.userData.wxapp_wxstatus =
        //   this.userData.wxapp.wxstatus - 0 ? true : false;
        // this.userData.status = this.userData.status ? true : false;
        // this.unified_price_type = this.unified_price_type ? true : false;
        this.userDataIndex = obj.$index;
        this.formLabelAlign = this.userSearchData[obj.$index];
      }
      if (index) {
        this.formLabelAlign = {
          projects: [],
          products: [],
          status: "1",
          picture: "",
          sort: 1,
          exp_type: 0,
          exp_date: 30,
          exp_alarm_day: 7,
          commi_amount: 0,
          commi_type: 1,
          price: 1,
          // wxapp: false,
          // wxapp_wxstatus: false,
          // status: "0",
          // price: "100",
          // experience_price: "88",
          // unified_price: "99",
        };
        console.log(this.formLabelAlign);
      }
      this.dialogVisible = !this.dialogVisible;
    },
    del(obj) {
      console.log(obj);
      let d = this.userSearchData[obj.$index];
      this.$confirm("此操作将永久删除该次卡, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delList(d);
        })
        .catch(() => {});
    },
    delivery(obj) {
      console.log(obj);
      if (obj) {
        this.fromData = obj.row;
      } else {
        this.fromData = {};
      }
      this.dialogVisible1 = !this.dialogVisible1;
    },
    cencels() {
      this.dialogVisible = !this.dialogVisible;
      this.userDataIndex = "";
    },
    saves(obj) {
      let d = JSON.parse(JSON.stringify(this.formLabelAlign));

      // d.commi_type = d.commi_type ? "2" : "1";
      let len = d.length;

      for (let i = 0; i < len; i++) {
        for (let l = 0; l < d[i].projects.length; l++) {
          d[i].projects[l].project_id = d[i].projects[l].project_ids;
        }
        for (let k = 0; k < d[i].products.length; k++) {
          d[i].products[k].product_id = d[i].products[k].product_ids;
        }
      }

      if (!d.name) {
        this.$message({
          message: "请输入套餐名",
          type: "warning",
        });
        return false;
      }
      if (!d.price) {
        this.$message({
          message: "请输入价格",
          type: "warning",
        });
        return false;
      }
      if (!d.exp_date) {
        this.$message({
          message: "请输入有效期",
          type: "warning",
        });
        return false;
      }
      //   if (d.exp_type == "") {
      //     this.$message({
      //       message: "请输入有效期类型",
      //       type: "warning",
      //     });
      //     return false;
      //   }

      //   if (!d.commi_amount) {
      //     this.$message({
      //       message: "请输入提成金额",
      //       type: "warning",
      //     });
      //     return false;
      //   }

      //   if (!d.projects.length && !d.products.length) {
      //     this.$message({
      //       message: "最少选择一项服务或一件商品",
      //       type: "warning",
      //     });
      //     return false;
      //   }
      //   console.log(d.projects, d.products);
      if (!d.projects.length) {
        this.$message({
          message: "最少选择一项服务",
          type: "warning",
        });
        return false;
      }
      if (d.projects.length) {
        for (let i = 0; i < d.projects.length; i++) {
          if (d.projects[i].project_id === "") {
            this.$message({
              message: "请选择服务类型",
              type: "warning",
            });
            return false;
          }
        }
      }
      //   if (d.products.length) {
      //     for (let i = 0; i < d.products.length; i++) {
      //       if (d.products[i].product_id === "") {
      //         this.$message({
      //           message: "请选择商品",
      //           type: "warning",
      //         });
      //         return false;
      //       }
      //     }
      //   }
      d.project_id = d.projects[0].project_id;
      d.project_count = d.projects[0].count;
      d.projects = JSON.stringify(d.projects);
      d.products = JSON.stringify(d.products);

      if (obj) {
        this.addList(d);
        return false;
      }
      // console.log(d,this.formLabelAlign)
      this.updateList(d);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
      console.log(file);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pages = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pages = val;
      this.getList();
    },
    distribute() {
      this.$message({
        type: "success",
        message: "分发成功!",
      });
      this.dialogVisible1 = !this.dialogVisible1;
    },
    update(file) {
      const isJPG = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isJPG.includes(file.file.type)) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 、WEBP格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      let d = new FormData();
      d.append("image", file.file);
      d.append("token", sessionStorage.getItem("token"));
      this.$axios({
        href: "/api/app/meiye/upload/project",
        data: d,
      }).then((res) => {
        console.log(res, res.data.data.url);
        if (this.userDataIndex == "") {
          this.formLabelAlign.picture = res.data.data.url;
        } else {
          this.userSearchData[this.userDataIndex].picture = res.data.data.url;
        }
      });
    },
  },
  created() {
    this.getList();
    this.getType();
  },
};
</script>
<style lang="scss" scoped>
.mealSet {
  width: 100%;
  height: 100%;
  display: flex;
  &-photo {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  &-right {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    // background: pink;
    &-cont {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;
      &-head {
        width: 100%;
        // min-width: 1200px;
        height: 60px;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        position: relative;
        overflow: auto;
        // background: red;
        &-list {
          // width: 100%;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            display: inline-block;
            width: 50px;
          }
        }
        &-list:nth-of-type(1) {
          width: 445px;
        }
        &-list:nth-of-type(2) {
          width: 128px;
          margin-left: 10px;
        }
        &-list:nth-of-type(3) {
          width: 128px;
          margin-left: 10px;
        }
        // &-list:nth-of-type(4) {
        //   width: 100px;
        //   margin-left: 10px;
        // }
        &-list:nth-of-type(4) {
          width: 120px;
          position: absolute;
          right: 10px;
          top: 0;
        }
      }
      &-page {
        width: 100%;
        height: 60px;
        margin-top: 20px;
      }
    }
  }

  &-edit {
    width: 100%;
    min-height: 300px;
    display: flex;
    // background: red;
    &-display {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-list {
      //  width: 33.33%;
      box-sizing: border-box;
      min-height: 500px;

      &-item {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        span {
          display: inline-block;
          width: 70px;
        }
        &-input {
          width: 320px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-btn {
          position: absolute;
          cursor: pointer;
          background: #8088a8;
          color: #fff;
          top: 8px;
          left: 0;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &-item-display {
        justify-content: flex-start;
      }
    }

    &-list:nth-of-type(1) {
      padding: 24px;
      width: 224px;
      margin: 0 28.5px;
      // background: pink;
      min-height: 360px;
    }

    &-list:nth-of-type(2) {
      padding-top: 24px;
      min-width: 406px;
      min-height: 360px;
      margin-right: 57.5px;
      // background: blue;
    }
    &-list:nth-of-type(3) {
      padding-top: 24px;
      min-width: 415px;
      min-height: 360px;
      // background: pink;
    }
    &-other {
      width: 100%;
      min-height: 100px;
      &-title {
        width: 100%;
        height: 60px;
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: Bold;
        text-align: left;
        color: #000000;
        line-height: 60px;
      }
      &-cont {
        width: 100%;
        min-height: 60px;
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }

  // &-edit {
  //   width: 100%;
  //   min-height: 500px;
  //   &-list {
  //     width: 100%;
  //     min-height: 40px;
  //     margin-bottom: 10px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-start;
  //     img {
  //       width: 148px;
  //       height: 148px;
  //       margin-left: 15px;
  //     }
  //     &-item {
  //       width: 100%;
  //       height: 100%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: flex-start;
  //       margin-right: 10px;
  //       span {
  //         display: inline-block;
  //         display: flex;
  //         align-items: center;
  //         justify-content: flex-start;
  //         width: 120px;
  //       }
  //       &-btn {
  //         cursor: pointer;
  //         width: 25px;
  //         height: 60px;
  //         margin: auto;
  //         background: #fff
  //           url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Del_Red.png)
  //           left center/24px no-repeat;
  //         border: none;
  //         outline: none;
  //         box-sizing: border-box;
  //         padding: 0;
  //         margin-left: 5px;
  //       }
  //     }
  //     &-item:last-child {
  //       margin-right: 0px;
  //     }
  //   }
  // }
}
</style>

<style moudle>
.mealSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}

.mealSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.mealSet-right-cont-head {
  transition: all 0.5s;
}

.select {
  width: 80%;
}
.input {
  width: 100%;
}
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}

@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
/* .avatar-uploader {
  font-size: 20px;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;
  margin-right: 10px;
  border-radius: 10px;
  margin-left: 10px;
}
.avatar-uploader:hover {
  border: 1px dashed #409eff;
} */
.el-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edui-editor {
  z-index: 0 !important;
}
.radius .el-input__inner {
  border-radius: 20px;
}
.nums {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}
.nums-input {
  width: 60%;
  height: 100%;
}
.nums-input .el-input-number {
  width: 100%;
}
.nums-select {
  width: 40%;
  height: 100%;
}
</style>
